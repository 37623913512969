import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import familyLawPhoto from "../../images/family-of-four-walking-at-the-street-2253879.jpg"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="Family Law Practice | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>Family Law</h1>

				<div id="content">
					<p>Some people give alimony a bad name. They assume it is just one more way for a woman or man to swindle money from the ex-spouse. In reality, alimony (or spousal support) helps ensure an equal standard of living between divorcing couples. It helps make it possible for someone to get back on his or feet and prosper without the former spouse's income.</p>
					<p className="text-center"><strong>If you have questions about alimony, please <span className="text-underline"><a href="../Contact-FL.html">contact</a></span><strong>&nbsp;Gina H. McDonald &amp; Associates at (205) 982-3325</strong> </strong></p>

					<p>Divorce cases often include alimony discussions if one spouse was the primary income provider for the family. However, payments are not automatic, like <a href="../Child-Custody-and-Visitation/Child-Support.html">child support</a>. Whether someone is a candidate to receive alimony depends on several factors including the number of years of marriage, the amount of education and job training both parties have, and yearly income.</p>

					<ul>
						<li><strong>Periodic alimony</strong> - Alimony can serve as supplemental income for a spouse after a divorce. This alimony can be temporary or indefinite. </li>
						<li><strong>Alimony in gross</strong> - Many of our clients own houses, businesses or other property with their spouse. Alimony in gross is a fixed amount given to a former spouse as part of a property settlement. </li>
					</ul>
					
				</div>
			</Col>
			<Col md="4">
				<img className="img-fluid" src={familyLawPhoto} alt="family holding hands" />
				<h3>Contact a Skilled Alimony Attorney</h3>
					<p>If you have questions about alimony, please contact the Birmingham, Alabama, law offices of Gina H. McDonald &amp; Associates. We can be reached by phone at (205) 982-3325, <a href="../Contact-FL.html">e-mail</a> , or by filling out the confidential <a href="../Contact-FL.html">intake form</a> on our Contact Us page.</p>
			</Col>
		</Row>
	</Container>
</Layout>